// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-calidades-js": () => import("./../../src/pages/calidades.js" /* webpackChunkName: "component---src-pages-calidades-js" */),
  "component---src-pages-contact-js": () => import("./../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-galeria-js": () => import("./../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-historia-panama-hat-js": () => import("./../../src/pages/historia-panama-hat.js" /* webpackChunkName: "component---src-pages-historia-panama-hat-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nuestra-empresa-js": () => import("./../../src/pages/nuestra-empresa.js" /* webpackChunkName: "component---src-pages-nuestra-empresa-js" */),
  "component---src-pages-para-el-js": () => import("./../../src/pages/para-el.js" /* webpackChunkName: "component---src-pages-para-el-js" */),
  "component---src-pages-para-ella-js": () => import("./../../src/pages/para-ella.js" /* webpackChunkName: "component---src-pages-para-ella-js" */),
  "component---src-pages-servicios-js": () => import("./../../src/pages/servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */),
  "component---src-pages-tallas-js": () => import("./../../src/pages/tallas.js" /* webpackChunkName: "component---src-pages-tallas-js" */),
  "component---src-pages-tenido-js": () => import("./../../src/pages/tenido.js" /* webpackChunkName: "component---src-pages-tenido-js" */),
  "component---src-templates-product-template-js": () => import("./../../src/templates/product-template.js" /* webpackChunkName: "component---src-templates-product-template-js" */)
}

